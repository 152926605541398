/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
	min-width: 320px;
	background: #fff;
	font-family: $font-family-sans-serif;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: #000;
}

a {
	color: inherit;
	text-decoration: underline;

	&:hover,
	&[href^="tel"] {
		text-decoration: none;
	}
}
