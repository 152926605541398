// Command Prompt
.command-prompt {
	&__head {
		display: flex;
		align-items: center;
		padding: 2px 5px;
		background-color: $white;

		svg {
			margin-right: 5px;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: 400;
			letter-spacing: .03em;
		}
	}

	&__body {
		height: calc(100vh - 24px);
		padding: 5px 0;
		background-color: $black;
		overflow-y: auto;
		overflow-x: hidden;
		color: rgba($white, .8);
	}

	&__intro {
		margin-bottom: 15px;
	}
}
