/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

input::placeholder {
	color: inherit;
	opacity: 1;
}

textarea::placeholder {
	color: inherit;
	opacity: 1;
}

input:-webkit-autofill {
	-webkit-text-fill-color: inherit;
	-webkit-box-shadow: 0 0 0 1000px #fff inset;
}

.field {
}

.textarea {
}

.select {
}

.radio {
}

.checkbox {
}
