/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Mixins
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */
@import url(_sprite.css);
* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box; }
  *:before, *:after {
    box-sizing: inherit; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main {
  display: block; }

template {
  display: none; }

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: none; }

img,
iframe,
video,
audio,
object {
  max-width: 100%; }

img,
iframe {
  border: 0 none; }

img {
  height: auto;
  display: inline-block;
  vertical-align: middle; }

b,
strong {
  font-weight: bold; }

address {
  font-style: normal; }

svg:not(:root) {
  overflow: hidden; }

a,
button,
input[type='submit'],
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='image'],
label[for] {
  cursor: pointer; }

a[href^='tel'],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
textarea,
a[href^='tel'] {
  appearance: none; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  appearance: none; }

textarea {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; }

button,
select {
  text-transform: none; }

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }

nav ul,
nav ol {
  list-style: none outside none; }

/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */
body {
  min-width: 320px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.3;
  color: #000; }

a {
  color: inherit;
  text-decoration: underline; }
  a:hover, a[href^="tel"] {
    text-decoration: none; }

/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */
/*  Clear  */
.clear {
  @util clearfix; }

/*  Notext  */
.notext {
  @util text-hide; }

/*  Hidden  */
[hidden],
.hidden {
  display: none !important; }

/*  Alignleft  */
.alignleft {
  float: left; }

/*  Alignright  */
.alignright {
  float: right; }

/*  Disabled  */
[disabled],
.disabled {
  cursor: default; }

/*  Grid  */
.col {
  max-width: 100%;
  flex: 1; }
  .cols {
    display: flex;
    flex-flow: row wrap; }
  .col--1of2 {
    max-width: 50%;
    flex: 0 0 50%; }

/*  Responsive Helpers  */
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; }
  .visible-xs-block {
    display: block !important; }
  .visible-xs-inline {
    display: inline !important; }
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .hidden-sm {
    display: none !important; }
  .visible-sm-block {
    display: block !important; }
  .visible-sm-inline {
    display: inline !important; }
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 1024px) and (max-width: 1200px) {
  .hidden-md {
    display: none !important; }
  .visible-md-block {
    display: block !important; }
  .visible-md-inline {
    display: inline !important; }
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1201px) {
  .hidden-lg {
    display: none !important; }
  .visible-lg-block {
    display: block !important; }
  .visible-lg-inline {
    display: inline !important; }
  .visible-lg-inline-block {
    display: inline-block !important; } }

/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */
.shell {
  max-width: 1220px;
  padding-right: 10px;
  padding-left: 10px;
  margin: auto; }
  .shell--fluid {
    max-width: none; }

/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Main
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Sidebar
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Content
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */
.btn {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  appearance: none; }
  .btn--block {
    display: block;
    padding-left: 0;
    padding-right: 0; }

/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */
input::placeholder {
  color: inherit;
  opacity: 1; }

textarea::placeholder {
  color: inherit;
  opacity: 1; }

input:-webkit-autofill {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0 1000px #fff inset; }

/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */
[class^="list-"] {
  list-style: none outside none; }

/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Widgets
\* ------------------------------------------------------------ */
.widgets {
  list-style: none outside none; }

/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */
.command-prompt__head {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  background-color: #fff; }
  .command-prompt__head svg {
    margin-right: 5px; }
  .command-prompt__head h1,
  .command-prompt__head h2,
  .command-prompt__head h3,
  .command-prompt__head h4,
  .command-prompt__head h5,
  .command-prompt__head h6 {
    font-weight: 400;
    letter-spacing: .03em; }

.command-prompt__body {
  height: calc(100vh - 24px);
  padding: 5px 0;
  background-color: #000;
  overflow-y: auto;
  overflow-x: hidden;
  color: rgba(255, 255, 255, 0.8); }

.command-prompt__intro {
  margin-bottom: 15px; }
